import React from 'react';
import Footer from "./pages/common/Footer";
import Home from "./pages/home/Home";
import {Route, Routes} from 'react-router-dom';
import ApiErrorPage from "./pages/docs/ApiErrorPage";
import Notice from "./pages/notice/Notice";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/home/home.css";
import NoticeRegister from "./pages/notice/NoticeRegister";
import AuthManager from "./pages/auth/AuthManager";
import NotFoundPage from "./pages/common/NotFoundPage";
import TermsPage from "./pages/docs/TermsPage";
import GuidePage from "./pages/docs/GuidePage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/api/oauth2" element={<AuthManager/>}/>
        <Route path="/" element={<Home/>}/>
        <Route path="/notice" element={<Notice/>}/>
        <Route path="/notice/register" element={<NoticeRegister/>}/>
        <Route path="/guide" element={<GuidePage/>}>
          <Route path="/guide/api" element={<ApiErrorPage/>}/>
          <Route path="/guide/terms" element={<TermsPage/>}/>
        </Route>
        <Route path="/*" element={<NotFoundPage/>}/>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
