import React from 'react';

interface ErrorCode {
  statusCode: number;
  errorCode: string;
  message: string;
}

const errorCodes: ErrorCode[] = [];

function TermsPage() {


  return (
    <main className="docs-content terms">
      <header>
        <h4>Dailyge 약관 및 개인정보 처리방침</h4>

      </header>
      <div>
        <h1><b>개인정보 처리방침</b></h1>
        <h2 style={{marginTop: "5rem"}}><b> 1. 개인정보 처리방침의 의의</b></h2>
        Dailyge는 이용자의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.

        

        <h2><b>2. 수집하는 개인정보</b></h2>
        Dailyge는 일정관리 서비스 제공을 위해 다음의 개인정보를 수집합니다.
        <li>필수항목: 이메일 주소, 비밀번호</li>
        <li>선택항목: 프로필 이미지</li>
        
        서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
        <li>IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록</li>

        

        <h2><b>3. 수집한 개인정보의 이용</b></h2>
        Dailyge는 수집한 개인정보를 다음의 목적으로 이용합니다.

        <li>회원 가입 및 관리</li>
        <li>일정관리 서비스 제공</li>
        <li>신규 서비스 개발 및 맞춤 서비스 제공</li>
        <li>통계학적 특성에 따른 서비스 제공 및 광고 게재</li>
        <li>서비스의 유효성 확인</li>
        <li>이용자의 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계</li>

        

        <h2><b>4. 개인정보의 제공 및 위탁</b></h2>
        Dailyge는 이용자의 별도 동의가 있는 경우나 법령에 규정된 경우를 제외하고는 이용자의 개인정보를 제3자에게 제공하지 않습니다.

        

        <h2><b>5. 개인정보의 보유 및 이용기간</b></h2>

        Dailyge는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 다만, 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 아래와 같이 관계법령에서 정한
        일정한 기간 동안 회원정보를 보관합니다.

        <li>계약 또는 청약철회 등에 관한 기록: 5년</li>
        <li>대금결제 및 재화 등의 공급에 관한 기록: 5년</li>
        <li>소비자의 불만 또는 분쟁처리에 관한 기록: 3년</li>

        

        <h2><b>6. 개인정보의 파기절차 및 방법</b></h2>

        원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.

        <li>파기절차: 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다.</li>
        <li>파기방법: 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</li>

        

        <h2><b>7. 이용자 및 법정대리인의 권리와 그 행사방법</b></h2>

        이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며, Dailyge의 개인정보 처리에 동의하지 않는 경우 동의를
        거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

        이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신
        후 직접 열람, 정정 또는 탈퇴가 가능합니다.

        

        <h2><b>8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</b></h2>

        Dailyge는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의
        브라우저에게 보내는 소량의 정보이며 이용자의 컴퓨터 하드디스크에 저장되기도 합니다.

        이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을
        거부할 수도 있습니다.

        

        <h2><b>9. 개인정보 처리방침의 변경</b></h2>

        이 개인정보 처리방침은 법령 및 방침에 따라 변경될 수 있으며, 변경 시 Dailyge는 변경 사항에 대하여 즉시 홈페이지에 게시합니다.

        <li>공고일자: 2024년 9월 26일</li>
        <li>시행일자: 2024년 10월 3일</li>

        
      </div>
      
    </main>
  );
};

export default TermsPage;
